<template>
<div>
    <!-- <v-dialog v-model="dialog" v-if="dialog" fullscreen> -->
    <v-card>
        <div id="map"></div>

    </v-card>

    <v-card elevation="3" class="pl-4 pr-4 pt-4 pb-4">
        <v-row style="margin:auto">
            <h4>Detalle</h4>
        </v-row>

        <v-divider></v-divider>

        <v-row style="margin:auto" justify="center">
            <v-card elevation="3" class="pl-4 pr-4 pt-4 pb-4" width="70%" v-if="data.PnlID > 0">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <h2>{{data.PnlNameBusiness}} - {{data.PnlStatusName}}</h2>
                            {{data.TypeProductName}}
                        </v-col>
                    </v-row>

                    <v-divider class="mt-2"></v-divider>
                    <v-row class="mt-3">
                        <v-col cols="6">
                            <v-row syle="margin:auto">
                                <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                        <h5>Datos Generales.</h5>
                                    </v-card-title>
                                </v-card>

                                <v-col cols="12">
                                    <li>Precio: {{data.PnlPrice}}</li>
                                    <li>Tipo Alquiler: {{data.TypeRentName}}</li>
                                    <li>Fec. Inicio: {{$fun.formatDateView(data.PnlDateBegin)}}</li>
                                    <li>Fec. Fin: {{$fun.formatDateView(data.PnlDateEnd)}}</li>
                                    <li>Contrato: {{data.PnlContract ? "Si" : "No"}}</li>
                                    <li>Iluminacion: {{data.PnlIluminacion ? "Si" : "No"}}</li>
                                    <li>Dias Vigencia:
                                        <v-chip dark small :color="data.DiasVigencia <=0 ? 'red' : 'blue'">{{data.DiasVigencia}}</v-chip>
                                    </li>
                                    <li>Medidas: {{data.PnlMedidas}}</li>
                                    <li>Caras: {{data.PnlCaras}}</li>
                                    <li>Tipo Zona: {{data.TypeZoneName}}</li>

                                    <li>Licencia: {{data.PnlLicenseName}}</li>
                                    <li>Direccion: {{data.PnlAddress}}</li>
                                    <li>Referencia: {{data.PnlReference}}</li>
                                    <li>Ubigeo: {{data.PnlUbigee}}</li>
                                    <li>Tipo Zona: {{data.TypeZone}}</li>
                                </v-col>

                            </v-row>

                            <v-row syle="margin:auto">
                                <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                        <h5>Datos Geofraficos.</h5>
                                    </v-card-title>
                                </v-card>

                                <v-col cols="12">
                                    <li>Lat: {{data.lat}}</li>
                                    <li>Lng: {{data.lng}}</li>
                                    <li>{{data.PnlOtherData}}</li>
                                    <li>{{data.DmsLocation}}</li>

                                    <br><b>Como Llegar</b><br><a :href="data.Uri" target="_blank">{{data.Uri}}</a>
                                </v-col>

                            </v-row>

                        </v-col>
                        <v-col cols="6">
                            <v-row syle="margin:auto">
                                <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%" class="mt-0">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                        <h5>Datos Proveedor.</h5>
                                    </v-card-title>
                                </v-card>
                                <v-col cols="12" align="center">
                                    <h1>{{data.ProviderName}}</h1><br>
                                    <h2>{{data.SupRuc}}</h2><br>

                                </v-col>
                                <v-col cols="12">

                                    <li>{{data.SupConName}}</li>
                                    <li>{{data.SupEmail}}</li>
                                    <li>{{data.SupTelephone}}</li>
                                </v-col>

                            </v-row>

                            <!--  <li></li>
                            <li>{{data.ProviderName}}</li>
                            <li>{{data.SupConName}}</li>
                            <li>{{data.SupEmail}}</li>
                            <li>{{data.SupTelephone}}</li>

                            <li>{{data.PnlLatitude}}</li>
                            <li>{{data.PnlLongitude}}</li>
                            <li>{{data.PnlOtherData}}</li>
                            <li>{{data.PnlAddress}}</li>
                            <li>{{data.PnlUbigee}}</li>
                            <li>{{data.PnlReference}}</li>
                            <li>{{data.PnlProductName}}</li>
                            <li>{{data.TypeProductName}}</li>
                            <li>{{data.PnlNameBusiness}}</li>
                            <li>{{data.PnlIluminacion}}</li>
                            <li>{{data.PnlStatusName}}</li>
                            <li>{{data.DiasVigencia}}</li>
                            <li>{{data.Uri}}</li>
                            <li>{{data.PnlLicenseName}}</li>
                            <li>{{data.PnlIluminacionName}}</li>
                            <li>{{data.lat}}</li>
                            <li>{{data.lng}}</li> -->

                        </v-col>
                    </v-row>
                    <ul>

                    </ul>

                </v-card-text>
                <!-- {{data}} -->
            </v-card>
            <v-card elevation="3" class="pl-4 pr-4 pt-4 pb-4" width="30%" v-if="data.PnlID > 0">
                <v-row>
                    <v-col cols="12" style="padding-top: 0px" class="mt-4">
                        <label><b>Comentarios</b></label>
                        <v-btn elevation="0" small class="ml-2 capitalize" text color="info" @click="addComments()">Añadir Tareas</v-btn>
                    </v-col>
                    <v-col cols="12" style="padding-top: 0px">
                        <s-textarea v-model="Comments" ref="Comments" :height="100"></s-textarea>
                    </v-col>
                    <v-col cols="12" style="margin-top: -30px">
                        <v-switch v-model="recordar" hide-details label="Recordar"></v-switch>
                        <s-date v-if="recordar" v-model="date" label="Fecha"></s-date>
                    </v-col>
                    <v-col cols="12">
                        <v-card>
                            <v-toolbar dense elevation="0" color="#7a8ca9" dark>
                                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                                <v-toolbar-title>Tareas</v-toolbar-title>
                            </v-toolbar>

                            <v-list>
                                <v-list-item-group :active-class="''">

                                    <template v-for="(item, index) in task">

                                        <v-list-item :key="item.RccID">
                                            <v-list-item-avatar>
                                                <!--  <v-img :src="item.RccAvatar"></v-img> -->
                                                <v-row justify="space-around">
                                                    <v-avatar color="red">
                                                        <span class="white--text text-h5">{{item.NtpName !== undefined ? item.NtpName.split(" ")[0].substring(0,1) : ""}}{{item.NtpName !== undefined ? item.NtpName.split(" ")[1].substring(0,1) : ""}}</span>
                                                    </v-avatar>
                                                </v-row>

                                            </v-list-item-avatar>

                                            <v-list-item-content>

                                                <b>
                                                    <v-list-item-title>{{item.NtpName}}
                                                        <v-spacer></v-spacer> {{item.SecCreate}}
                                                    </v-list-item-title>
                                                </b>
                                                <v-divider></v-divider>

                                                {{item.RccSubtitleExtract}}

                                                <v-list-group v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                    <template v-slot:activator>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Ver mas</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>

                                                    <v-list-item v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                        <v-list-item-content>
                                                            {{item.RccSubtitle}}
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-group>

                                                <!-- <v-list-item-title style="font-size: 12px" v-html="item.RccSubtitle"></v-list-item-title> -->

                                            </v-list-item-content>

                                            <a style="color: red;font-size:10px" v-if="item.UsrCreateID == $fun.getUserID() && item.SecCreate >= $fun.getDate()" @click="deleteComments(item)">Eliminar</a>

                                        </v-list-item>
                                        <v-divider :key="index"></v-divider>
                                    </template>

                                </v-list-item-group>
                            </v-list>

                        </v-card>
                    </v-col>

                </v-row>
            </v-card>
        </v-row>

    </v-card>
    <!--  </v-dialog> -->

</div>
</template>

<script>
import _sPanel from "@/services/Marketing/MktPanelPubli.js";

import {
    Loader
} from '@googlemaps/js-api-loader';
export default {
    data() {
        return {
            dialog: true,
            infowindow: null,
            items: [],
            itemsLoc: [],
            data: {},
            task: [],
            Comments: [],
            recordar: false,
            date: null
        }
    },
    created() {
        _sPanel.list({}, this.$fun.getUserID()).then(resp => {
            this.items = resp.data
            if (this.items == null || this.items.length == 0) {
                this.$fun.alert("No se han cargado Coordenadas.", "warning")
                return
            }

            if (this.items !== null) {
                this.items.forEach(element => {
                    element.PnlLicenseName = element.PnlLicense == true ? "Si" : "No"
                    element.PnlIluminacionName = element.PnlIluminacion == true ? "Si" : "No"
                    element.ProviderName = element.ProviderName == null ? "" : element.ProviderName
                    element.PnlAddress = element.PnlAddress == null ? "" : element.PnlAddress
                    element.DiasVigencia = element.DiasVigencia == null ? "" : element.DiasVigencia

                    console.log(
                        element
                    )
                    this.itemsLoc.push({
                        id: Number.parseInt(element.PnlID),
                        lat: Number.parseFloat(element.lat),
                        lng: Number.parseFloat(element.lng),
                        //descripcion: element.descripcion
                        //descripcion: "Proveedor: <b>" + element.ProviderName + "</b><br>Precio: <b>" + element.PnlPrice + "</b><br>Fec. Inicio: <b>" + this.$fun.formatDateView(element.PnlDateBegin) + "</b><br>Fec. Fin: <b>" + this.$fun.formatDateView(element.PnlDateEnd) + "</b><br>Referencia: " + element.PnlReference + "<br>Ubicaciòn: <a target='_blank'>" + element.Uri + "</a>",
                        descripcion: "<h2>" + element.PnlNameBusiness + "</h2>" +
                            "<br>Direccion: <b>" + element.PnlAddress + "</b>" +
                            "<br>Referencia: <b>" + element.PnlReference + "</b>" +
                            "<br>Estado: <b>" + element.PnlStatusName + "</b>" +
                            "<br>Licencia: <b>" + element.PnlLicenseName + "</b>" +
                            "<br>Proveedor: <b>" + element.ProviderName + "</b>" +
                            "<br>Tipo de Alquiler: <b>" + element.TypeRentName + "</b>" +
                            "<br>Costo Mensual: <b>" + element.PnlPrice + "</b>" +
                            "<br>Dìas Vigente: <b>" + element.DiasVigencia + "</b>" +
                            "<br>Iluminacion: <b>" + element.PnlIluminacionName + "</b>" +
                            "<br>Tipo de Producto: <b>" + element.TypeProductName + "</b>" +
                            "<br><b><a target='_blank' href=" + element.Uri + ">" + "Ver en Google Maps" + "</a></b>",
                        /* descripcion: 
                            "<b>" + element.PnlNameBusiness + "</b>" +
                            "<br><b>" + element.PnlAddress + "</b>" +
                            "<br><b>" + element.PnlReference + "</b>" +
                            "<br><b>" + element.PnlStatusName + "</b>" +
                            "<br><b>" + element.PnlLicenseName + "</b>" +
                            "<br><b>" + element.ProviderName + "</b>" +
                            "<br><b>" + element.TypeRentName + "</b>" +
                            "<br><b>" + element.PnlPrice + "</b>" +
                            "<br><b>" + "" + "</b>" +
                            "<br><b>" + element.PnlIluminacionName + "</b>" +
                            "<br><b>" + element.TypeProductName + "</b>" +
                            "<br><b><a target='_blank' href="+ element.Uri +">" + "Ver en Google Maps"+ "</a></b>", */
                        icono: element.TypeProductIcono, //"https://cdn-icons-png.flaticon.com/128/3448/3448561.png"
                        color: "blue",
                        etiqueta: "Etiqueta 1"
                    })
                });

                this.loadMAP(this.itemsLoc)
            }
        })

    },

    methods: {
        loadMAP(items) {
            const loader = new Loader({
                apiKey: 'AIzaSyD1W-51gKocwSp94qpkmoR1zKTZGpq_jsY',
                version: "weekly",
                libraries: ["places"]
            });

            loader.load().then(() => {
                const coordenadas = items
                const map = new google.maps.Map(document.getElementById('map'), {
                    zoom: 13,
                    center: coordenadas[0],
                });

                coordenadas.forEach((coordenada) => {
                    const marker = new google.maps.Marker({
                        position: coordenada,
                        map: map,
                        icon: {
                            url: coordenada.icono,
                            fillColor: coordenada.color,
                            fillOpacity: 1,
                            strokeWeight: 0,
                            scale: 8,
                            //url: "https://cdn-icons-png.flaticon.com/512/3721/3721984.png",
                            //url: "https://cdn-icons-png.flaticon.com/128/3448/3448561.png",
                            scaledSize: new google.maps.Size(40, 40) // Tamaño del icono
                        }
                    });

                    const infowindow = new google.maps.InfoWindow({
                        content: coordenada.descripcion,
                    });

                    marker.addListener('click', () => {
                        if (this.infowindow) {
                            this.infowindow.close();
                        }
                        infowindow.open(map, marker);
                        console.log("open", coordenada)

                        this.infowindow = infowindow;

                        if (coordenada.id > 0) {

                            this.data = this.items.find(x => x.PnlID == coordenada.id);

                            this.listComments()

                            /* _sPanel.listcomments(this.data, this.$fun.getUserID()).then(resp => {
                                resp.data.forEach(element => {
                                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                                    element.RccSubtitleExtract = element.RccSubtitle.substring(0, 300)
                                    element.RccLengthExtract = element.RccSubtitleExtract.length
                                    element.RccLengthSubtitle = element.RccSubtitle.length
                                });
                                this.task = resp.data
                                console.log("listcomments", resp.data)
                            }) */

                            /*  _sPanel.byID({
                                 PnlID: coordenada.id
                             }).then(resp => {
                                 console.log(resp.data)

                             }) */
                        }
                    });
                });
            });
        },

        listComments() {
            _sPanel.listcomments(this.data, this.$fun.getUserID()).then(resp => {
                resp.data.forEach(element => {
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    element.RccSubtitleExtract = element.RccSubtitle.substring(0, 300)
                    element.RccLengthExtract = element.RccSubtitleExtract.length
                    element.RccLengthSubtitle = element.RccSubtitle.length
                });
                this.task = resp.data
                console.log("listcomments", resp.data)
            })
        },

        addComments() {

            if (this.Comments.length == 0) {
                /* this.$fun.alert("Digite Comentario", "warning") */
                this.$refs.Comments.error("Digite Comentario")
                return
            }

            console.log(this.data)

            if(!this.recordar){
                this.date = null
            }

            this.$fun.alert("¿Seguro de añadir comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    let item = {
                        PnlID: this.data.PnlID,
                        RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                        RccTitle: "New Comments",
                        RccSubtitle: this.Comments,
                        DateRecordatorio: this.date,
                        SecStatus: 1,
                        UsrCreateID: this.$fun.getUserID()
                    }

                    _sPanel.savecomments(item, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.listComments()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        deleteComments(item) {

            item.SecStatus = 0
            item.UsrUpdateID = this.$fun.getUserID()

            this.$fun.alert("¿Seguro de eliminar comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sPanel.savecomments(item, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.listComments()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },
    },

    mounted() {

    },

};
</script>

<style lang="scss" scoped>
#map {
    height: 550px;
    /* Ajusta la altura según tus necesidades */
}

.custom-zoom-control {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
}

.zoom-in,
.zoom-out {
    font-weight: bold;
    line-height: 1;
    padding: 5
}

.etiqueta-texto {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    white-space: nowrap;
}
</style>
